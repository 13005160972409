import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr, isCompatMode } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';
import { replaceByArtnr } from '@/helpers/tile';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class ExklusivTile extends RoofTile {
  public constructor() {
    super(TileFamily.Exklusiv, 280, 240);
    this.label = 'Exklusiv 1-kupig';
    this.key = 'exklusiv';
    this.material = Material.Betong;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 375,
      },
      [MarketStr.Norge]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 375,
      },
      [MarketStr.Tyskland]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 375,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 340,
      [MarketStr.Norge]: 370,
      [MarketStr.Tyskland]: 340,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: '010024',
      [RoofArticle.NockPanna]: '030024',
      [RoofArticle.NockPannaAlt]: '033024',
      [RoofArticle.NockBörjan]: '031524',
      [RoofArticle.NockSlut]: '031624',
      [RoofArticle.BörjanValm]: isCompatMode() ? '031024' : '031524',
      [RoofArticle.BörjanValmAlt]: '031824',
      [RoofArticle.NockBörjanAlt]: '031824',
      [RoofArticle.NockSlutAlt]: '032024',
      [RoofArticle.NockTBörjan]: '036024',
      [RoofArticle.NockTSlut]: '036124',
      [RoofArticle.ValmKlocka]: '035024',
      [RoofArticle.ValmKlockaBörjan]: '035224',
      [RoofArticle.ValmKlockaSlut]: '035324',
      [RoofArticle.NockXLutande]: '037024',
      [RoofArticle.NockXLutandeAlt]: '037424',
      [RoofArticle.NockXPlan]: '037124',
      [RoofArticle.GavelPannaVänster]: '041124',
      [RoofArticle.GavelPannaHöger]: '041024',
      [RoofArticle.GavelPannaVänsterBred]: '040124',
      [RoofArticle.GavelPannaHögerBred]: '040024',
      [RoofArticle.GavelBeslag]: '041624',
      [RoofArticle.Ventilationshuv]: '052124',
      [RoofArticle.Ventilationshuv2]: '088624',
      [RoofArticle.VentilationshuvMontering]: '060200',
      [RoofArticle.AvluftarePlåt]: '051120',
      [RoofArticle.AvluftarePlåtMontering]: '060100',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: '083124',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '081820',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.SpikPanna]: '094291',
      [RoofArticle.Stormclips]: '096190',
      [RoofArticle.EasyClip]: '096791',
      [RoofArticle.EasyClip50]: '096791',
      [RoofArticle.EasyClip45x70]: '067191',
      [RoofArticle.EasyClip30x48]: '067691',
      [RoofArticle.EasyClip36x48]: '068191',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.NockskruvAlt]: '094691',
      [RoofArticle.Nockklammer]: '114724',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Security
      [RoofArticle.Bärläktsteg45]: '076120',
      [RoofArticle.Bärläktsteg25]: '076120',
      [RoofArticle.KoppladeTaksteg]: '084120',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SpårPanna]: '073424',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '081890',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Ventilationshuv]: '088624',
        [RoofArticle.Valmtätning300]: '092420',
      },
    };
  }

  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    // 310 <= Läktavstånd <= 375
    if (lAvstånd && (lAvstånd < 310 || lAvstånd > 375)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number): number {
    const ang = Number(angle.toFixed(1));
    if (ang >= 14 && ang <= 27) return 40;
    if (ang >= 27.1 && ang <= 35) return 25;
    if (ang >= 35.1 && ang <= 80) return 15;
    return 40;
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
            items.push(...['052197', '051197', '059897']);
            break;
          case AccessoryColor.Black:
            items.push(...['052120', '051120', '059820']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['052124', '051124', '059824']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}
