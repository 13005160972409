import type { RoofTile } from '@/calculation/rooftiles/rooftile';
import { OptionsBuilder } from '@/calculation/rooftiles/options';
import { TileFamily, RoofArticle, MarketStr, Underlagstak } from '@/calculation/common';
import { FixingsTile } from '@/enums';

export class FixingsTileOptionsBuilder extends OptionsBuilder {
  constructor(rooftile: RoofTile, market: MarketStr) {
    super(rooftile, market, {
      [FixingsTile.EasyClip]: RoofArticle.EasyClip,
      [FixingsTile.EasyClip45x70]: RoofArticle.EasyClip45x70,
      [FixingsTile.EasyClip30x48]: RoofArticle.EasyClip30x48,
      [FixingsTile.EasyClip36x48]: RoofArticle.EasyClip36x48,
      [FixingsTile.Screw]: RoofArticle.SkruvPanna,
      [FixingsTile.Nail]: RoofArticle.SpikPanna,
      [FixingsTile.Stormclips]: RoofArticle.Stormclips,
      [FixingsTile.DiagonalClip]: RoofArticle.DiagonalClip,
      [FixingsTile.DiagonalClipZial]: RoofArticle.DiagonalClipZial,
      [FixingsTile.SidefoldStaples]: RoofArticle.SidofallsKlammer,
      [FixingsTile.RoofhookJP]: RoofArticle.TakkrokJP,
      [FixingsTile.SBracket]: RoofArticle.SKlammer,
      [FixingsTile.RooftileHook]: RoofArticle.Takpannekrok,
    });

    if (market != MarketStr.Norge) {
      this.remove(FixingsTile.EasyClip30x48, FixingsTile.EasyClip36x48);
    }
  }

  underlagstak(underlagstak: Underlagstak): FixingsTileOptionsBuilder {
    switch (underlagstak) {
      case Underlagstak.RåspontMedPapp:
        this.remove(FixingsTile.DiagonalClip, this.market === MarketStr.Norge ? FixingsTile.EasyClip : null, this.options.includes(FixingsTile.EasyClip) ? FixingsTile.EasyClip45x70 : null);
        break;
      default:
        this.remove(FixingsTile.DiagonalClipZial, this.market === MarketStr.Norge ? FixingsTile.EasyClip : null);
    }
    return this;
  }

  family(family: TileFamily): FixingsTileOptionsBuilder {
    if (family != TileFamily.Strängpressat2kupLertegel && this.options.includes(FixingsTile.EasyClip45x70))
      this.remove(FixingsTile.EasyClip);

    switch (family) {
      case TileFamily.Palema:
        if (this.market == MarketStr.Norge)
          this.keep(FixingsTile.Screw, FixingsTile.Nail, FixingsTile.Stormclips, FixingsTile.EasyClip30x48, FixingsTile.EasyClip36x48);
        else
          this.keep(FixingsTile.EasyClip, FixingsTile.EasyClip45x70, FixingsTile.Screw, FixingsTile.Nail, FixingsTile.Stormclips);
        break;

      case TileFamily.Exklusiv:
        if (this.market == MarketStr.Norge)
          this.keep(FixingsTile.Stormclips, FixingsTile.EasyClip30x48, FixingsTile.EasyClip36x48);
        else
          this.keep(FixingsTile.EasyClip, FixingsTile.EasyClip45x70, FixingsTile.Stormclips);
        break;

      case TileFamily.Carisma:
        this.keep(FixingsTile.Screw, FixingsTile.Stormclips);
        break;

      case TileFamily.PianoFalsatLertegel:
        if (this.market == MarketStr.Norge)
          this.keep(FixingsTile.SidefoldStaples, FixingsTile.EasyClip30x48, FixingsTile.EasyClip36x48);
        else
          this.keep(FixingsTile.EasyClip, FixingsTile.DiagonalClip, FixingsTile.DiagonalClipZial);
        break;

      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
        if (this.market == MarketStr.Norge)
          this.keep(FixingsTile.SidefoldStaples, FixingsTile.DiagonalClipZial, FixingsTile.EasyClip30x48, FixingsTile.EasyClip36x48);
        else
          this.keep(FixingsTile.EasyClip, FixingsTile.EasyClip45x70, FixingsTile.DiagonalClip, FixingsTile.DiagonalClipZial, FixingsTile.SidefoldStaples);
        break;

      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
        if (this.market == MarketStr.Norge)
          this.keep(FixingsTile.SidefoldStaples, FixingsTile.EasyClip30x48, FixingsTile.EasyClip36x48);
        else
          this.keep(FixingsTile.EasyClip, FixingsTile.EasyClip45x70, FixingsTile.DiagonalClip, FixingsTile.DiagonalClipZial, FixingsTile.SidefoldStaples);
        break;

      case TileFamily.Strängpressat2kupLertegel:
        this.keep(this.market === MarketStr.Sverige ? FixingsTile.EasyClip : null, FixingsTile.RoofhookJP, FixingsTile.SBracket, FixingsTile.RooftileHook);
        break;
    }
    return this;
  }

  setDefault(family: TileFamily, underlagstak: Underlagstak): FixingsTileOptionsBuilder {
    let def = FixingsTile.None;

    switch (this.market) {
      case MarketStr.Norge:
        switch (family) {
          case TileFamily.Palema:
          case TileFamily.Exklusiv:
          case TileFamily.Carisma:
            def = FixingsTile.Stormclips;
            break;
          case TileFamily.HansaFalsatLertegel:
          case TileFamily.HansaFalsatLertegelRakFramkant:
          case TileFamily.TvillingFalsatLertegel:
          case TileFamily.TvillingLertegelRakFramkant:
          case TileFamily.PianoFalsatLertegel:
            def = FixingsTile.SidefoldStaples;
            break;
          case TileFamily.Strängpressat2kupLertegel:
            def = FixingsTile.RoofhookJP;
            break;
        }
        break;
      default:
        switch (family) {
          case TileFamily.Palema:
          case TileFamily.Exklusiv:
          case TileFamily.HansaFalsatLertegel:
          case TileFamily.HansaFalsatLertegelRakFramkant:
          case TileFamily.TvillingFalsatLertegel:
          case TileFamily.TvillingLertegelRakFramkant:
          case TileFamily.Strängpressat2kupLertegel:
            def = this.options.includes(FixingsTile.EasyClip45x70) ? FixingsTile.EasyClip45x70 : FixingsTile.EasyClip;
            break;
          case TileFamily.Carisma:
            def = FixingsTile.Screw;
            break;
          case TileFamily.PianoFalsatLertegel:
            if (underlagstak === Underlagstak.RåspontMedPapp) {
              def = FixingsTile.EasyClip;
            } else {
              def = FixingsTile.DiagonalClip;
            }
            break;
        }
    }

    this.default = def;
    return this;
  }
}
